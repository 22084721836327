<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="iq-card">
        <div class="iq-card-body p-0">
          <div class="iq-edit-list">
            <ul class="iq-edit-profile nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
              <li class="flex-fill p-0">
                <a class="nav-link active" data-toggle="pill" href="#profile">
                  {{cvProfileInfo}}
                </a>
              </li>
              <li class="flex-fill p-0">
                <a class="nav-link disabled" data-toggle="pill" href="#about">
                  {{cvAbout}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12" >
      <div class="iq-edit-list-data">
        <div class="tab-content">
          <div class="tab-pane fade active show" id="profile" role="tabpanel">
            <b-card class="mb-4 p-3">
              <ValidationObserver ref="pbaForm">
                <b-form>
                  <div>
                    <h4 class="mb-3">
                      {{cvPbaCreateAsLabel}}<label class="primary-color font-size-16">*</label>
                    </h4>
                    <ValidationProvider
                      name="Create Activity as"
                      rules="required"
                      v-slot="{ errors }">
                      <multiselect
                        v-model="vmCreateActAs"
                        :options="cvPostAsOptions"
                        track-by="id"
                        label="name"
                        :placeholder="cvPbaCreateAsLabel"
                        :tag-placeholder="cvPbaCreateAsLabel">
                      </multiselect>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-4 mt-4 pl-0">
                    <h4 class="mb-3">
                      {{cvPBSNameLabel}}<label class="primary-color font-size-16">*</label>
                    </h4>
                    <ValidationProvider
                      name="Activity Name"
                      rules="required|max:300"
                      v-slot="{ errors }">
                      <input v-model="vmPBAFormData.event_name" :placeholder="cvPBSNameLabel" type="text" class="form-control" required  />
                      <span class="text-danger">
                        <span v-if="vmPBAFormData.event_name && errors[0]"> Activity name may not be more than 300 characters. </span>
                        <span v-else> {{ errors[0] }} </span>
                      </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-4 mt-4 pl-0">
                    <h4 class="mb-3">
                      {{cvEventHostingPlatform}}<label class="primary-color font-size-16">*</label>
                    </h4>
                    <ValidationProvider
                      :name="cvEventHostingPlatform"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <select class="form-control" v-model="vmEventHostingPlatform" id="validation_event_hosting_platform">
                        <option v-for="(hosting_platform, index) of eventHostingPlatformConfig" :key="index+'hosting_platform'" :value="index">
                          {{hosting_platform}}
                        </option>
                      </select>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-4 mt-4 pl-0">
                    <h4 class="mb-3">
                      {{cvEventTypeLabel}}<label class="primary-color font-size-16">*</label>
                    </h4>
                    <ValidationProvider
                      name="Event Type"
                      rules="required"
                      v-slot="{ errors }">
                      <multiselect
                        v-model="vmEventType"
                        :options="cvEventTypesOptions"
                        track-by="name"
                        label="name">
                      </multiselect>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="mt-4">
                    <h4 class="mb-3">
                      {{ cvPBACoverPic }}<label class="primary-color font-size-16">*</label>
                    </h4>
                    <div class="form-group row align-items-center">
                      <div class="col-md-12">
                        <div class="profile-img-edit border" style="height: 200px; width: 300px;">
                          <img v-if="vmCoverPic" :src="vmCoverPic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
                          <i v-else class="fas fa-image d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                          <label class="p-image">
                            <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                            <input class="file-upload" type="file" required accept="image/png,image/jpeg,image/jpg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                          </label>
                        </div>
                      </div>
                      <span class="text-danger" ref="coverPicError"></span>
                    </div>
                  </div>
                  <div class="mt-4">
                    <b-btn type="button" variant="primary" class="pull-left" @click="eventAdd()" :disabled="cvLoadingStatus || cvImageLoadingStatus || cvImageError">
                      <b-spinner small class="ml-4 mr-4" v-if="cvLoadingStatus || cvImageLoadingStatus" label="Spinning">
                      </b-spinner>
                      <span v-else>{{cvPBACreateBtn}}</span>
                    </b-btn>
                  </div>
                </b-form>
              </ValidationObserver>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>
<style scoped lang="scss">
@media (max-width: 479px) {
  .profile-feed-items li{
    margin: 0px !important;
  }
}
</style>
  <style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<script>
import { socialvue } from "../../../config/pluginInit"
import { events } from "../../../FackApi/api/events"
import { Images } from "../../../FackApi/api/image"
import ApiResponse from "../../../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"
import CropImage from "../../../components/cropImage"
import Multiselect from "vue-multiselect"
import eventHostingPlatformConfig from "../../../FackApi/json/EventHostingPlatforms.js"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "eventAdd",
  components: {
    ValidationProvider,
    CropImage,
    Multiselect
  },
  data () {
    return {
      apiName: "event_add",
      cvSelectLabel: "Select ",
      cvLoadingStatus: false,
      cvImageLoadingStatus: false,
      cvProfileInfo: "Profile",
      cvAbout: "About",
      cvCardTitle: "Add Event",
      cvCardSubHeader: "",
      cvPbaCreateAsLabel: "Create activity as",
      cvPBSNameLabel: "Activity Name",
      cvEventHostingPlatform: "Hosting Platforms",
      cvEventTypeLabel: "is Gide Plus ?",
      cvPBACoverPic: "Event Profile Pic",
      cvPBACreateBtn: "Save & Next",
      showToast: false,
      showModelAskOtpBox: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Event",
      cvImageError: false,
      cvImageExtensionErrorMsg: " Only jpeg, webp and png is supported.",
      cvImageSizeErrorMsg: " Image size must be less than 300 kb.",
      vmPBAFormData: Object.assign({}, this.initPBAData()),
      vmPBACoverPicImage: Object.assign({}, this.initImageData()),
      vmProfilePic: "",
      vmCoverPic: "",
      pbaObj: {},
      propModuleName: "EVENT",
      propCropImageDialogOpen: false,
      fileSrc: "",
      updateImageType: null,
      propStencilSize: null,
      propCropDialogSize: "md",
      cvPostAsOptions: [],
      vmCreateActAs: null,
      vmEventType: null,
      vmEventHostingPlatform: null,
      eventHostingPlatformConfig: null,
      cvEventTypesOptions: [
        {
          name: "For All"
        },
        {
          name: "Gide+"
        }
      ]
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    this.eventHostingPlatformConfig = eventHostingPlatformConfig(this.userData.user_role)
    this.loadRequiredData()
  },
  methods: {
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * initPBAData
     */
    initPBAData () {
      return {
        event_name: "",
        event_module_id: ""
      }
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        image: "",
        image_type: ""
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
      this.getPostAsList()
    },
    /**
     * eventAdd
     */
    async eventAdd () {
      if (this.cvImageError) {
        return
      }
      let valid = await this.$refs.pbaForm.validate()
      if (!valid) {
        return
      }

      if (!this.vmCoverPic) {
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "Missing image banner for this event."
        return
      }

      try {
        if (this.vmCreateActAs && this.vmCreateActAs.id) {
          this.vmPBAFormData.event_module_id = this.vmCreateActAs.id
        }

        this.vmPBAFormData.event_master_type = this.vmEventType.name
        this.vmPBAFormData.event_hosting_platform = this.vmEventHostingPlatform

        this.cvLoadingStatus = true
        let eventAddResp = await events.eventAdd(this, this.vmPBAFormData)
        if (eventAddResp && !eventAddResp.resp_status) {
          await ApiResponse.responseMessageDisplay(this, eventAddResp)
          return false
        }

        if (eventAddResp.resp_data && eventAddResp.resp_data.event_id) {
          this.cvLoadingStatus = false
          await this.imageAdd(eventAddResp.resp_data.event_id)
          await this.$router.push("/event_edit/" + eventAddResp.resp_data.event_id + "?about=true")
        }
      }
      catch (err) {
        console.error("Exception occurred at eventAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * imageAdd
     */
    async imageAdd (pbaId) {
      if (this.cvImageError) {
        return
      }
      try {
        this.cvImageLoadingStatus = true
        let imageObj = {
          "module_name": "EVENT",
          "module_id": pbaId,
          "image": []
        }

        if (this.vmPBACoverPicImage.image) {
          imageObj.image.push(this.vmPBACoverPicImage)
        }

        if (imageObj.image.length < 1) {
          return false
        }

        const imageUpdateResp = await Images.imageProfileAdd(this, imageObj)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }
        this.vmPBACoverPicImage = Object.assign({}, this.initPBAData())
        imageObj = {}
      }
      catch (err) {
        console.error("Exception occurred at imageAdd() and Exception:", err.message)
      }
      finally {
        this.cvImageLoadingStatus = false
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 300 * 1024 // 300 KB in bytes
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.toastVariant = "danger"
            this.showToast = true
          }
          return false
        }
        this.propStencilSize = {
          "width": 1080,
          "height": 566
        }
        this.propCropDialogSize = "md"
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * onChangeProfileImage
     */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      this.vmPBACoverPicImage = Object.assign({}, this.initImageData())
      this.vmPBACoverPicImage.image = {
        "img": img,
        "name": orgImg.name
      }
      this.vmPBACoverPicImage.image_type = "cover_pic"
    },
    /**
     * getPostAsList
     */
    async getPostAsList () {
      try {
        // If user user is part of other modules
        if (this.userData.modules) {
          // Add clubs to post as option list
          if (this.userData.modules.clubs) {
            let clubs = this.userData.modules.clubs
            for (let index in clubs) {
              let club = clubs[index]
              let allowedToPost = this.getUserAllowedToPostOrNot(club.club_id)

              if (allowedToPost) {
                this.cvPostAsOptions.push(
                  { name: `${club.club_name} (Club)`, id: club.club_id, post_type: 3, "module_data": club }
                )
              }
            }
          }

          // Add organisation to post as option list
          if (this.userData.modules.organisations) {
            let orgs = this.userData.modules.organisations
            for (let index in orgs) {
              let org = orgs[index]
              let allowedToPost = this.getUserAllowedToPostOrNot(org.org_id)

              if (allowedToPost) {
                this.cvPostAsOptions.push(
                  { name: `${org.org_name} (Organisation)`, id: org.org_id, post_type: 1, "module_data": org }
                )
              }
            }
          }
        }

        if (this.userData.user_role === "USERROLE11111" || this.userData.user_role === "USERROLE11118") {
          // Admin
          this.cvPostAsOptions.unshift({ name: `${this.userData.user_name} (You)`, id: this.userData.user_id, "module_data": "Admin" })
        }
      }
      catch (err) {
        console.error("Exception occurred at getPostAsList() and Exception:", err.message)
      }
    },
    /**
     * getUserAllowedToPostOrNot
     * @param moduleObjId
     * @returns {boolean}
     */
    getUserAllowedToPostOrNot (moduleObjId) {
      if (this.userData.roles && this.userData.roles[moduleObjId]) {
        if (this.userData.roles[moduleObjId]["udr_name"] && this.userData.roles[moduleObjId]["udr_name"].toLowerCase() === "admin") {
          return true
        }
        else {
          let actions = this.userData.roles[moduleObjId]["actions"]
          for (let index in actions) {
            if (actions[index]["api_name"] === "event_add") {
              return true
            }
          }
          return false
        }
      }
      else {
        return false
      }
    }
  }
}
</script>
